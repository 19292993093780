<template>
  <div class="about">

     <el-image
        v-for="item in myFun(images)"
        :src="'http://yuncangai.com/ctapi/ct/image/get/'+item"
        style="height: 200px; width:auto; display: block; cursor: pointer" />


  </div>
</template>
<script>
 export default {
        data () {
            return {
                images:'1655771509892_WrOvXFPccRnmvjlV,1655771528703_dmdiqB6cfQ3aaQUy'
            }
        },
        methods: {
            myFun(ids) {
                return ids.split(',')
            },
        },

}
</script>
