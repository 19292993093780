<template>
    <div class="login-wrap">
        <div class="ms-login">
          <!--  <div class="ms-title">系统登录</div>-->

            <el-tabs v-model="param.loginType" :stretch="true">
                <el-tab-pane label="个人登录" name="user"></el-tab-pane>
                <el-tab-pane label="企业登录" name="company"></el-tab-pane>
            </el-tabs>

            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="account">
                    <el-input v-model="param.account" placeholder="企业账户名">
                        <template #prepend>
                            <el-button  class="iconfont icon-qiye"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item :prop="param.loginType == 'user'?'username':''" v-show="param.loginType == 'user'">
                    <el-input v-model="param.username" placeholder="用户名">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        type="password"
                        placeholder="密码"
                        v-model="param.password"
                        @keyup.enter="submitForm()"
                    >
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
              <!--  <p class="login-tips">Tips : 用户名和密码随便填。</p>-->
            </el-form>
        </div>
        <div style="bottom: 20px; position: absolute; width: 100%;text-align: center;">
            <br><a style="color: #d2d2d2" href="https://beian.miit.gov.cn">浙ICP备2021030305号-1</a>
        </div>
    </div>
</template>

<script>
   // import { login, addToken } from "../api/index";
    export default {
        data() {
            return {
                param: {
                    account:"",
                    username: "",
                    password: "",
                    loginType: "company",
                },
                rules: {
                    account: [
                        { required: true, message: "请输入企业账户名", trigger: "blur" }
                    ],
                    username: [
                        { required: true, message: "请输入用户名", trigger: "blur" }
                    ],
                    password: [
                        { required: true, message: "请输入密码", trigger: "blur" }
                    ]
                },
                userInfo:{}
            };
        },
        created() {
            this.$store.commit("clearTags");
        },
        mounted(){
            try {
                let p = JSON.parse(localStorage.getItem("loginParam"));
                this.param.loginType = p.loginType;
                this.param.account = p.account;
                this.param.username = p.username;
                this.param.password = p.password;
            } catch (e) {
            }
        },
        methods: {
            submitForm() {
                localStorage.setItem("loginParam", JSON.stringify(this.param));

                this.$refs.login.validate(valid => {
                    if (valid) {

                        try {
                            this.apiUtils.login({
                                companyAccount: this.param.account,
                                username: this.param.username,
                                password: this.param.password,
                                loginType: this.param.loginType
                            }).then(res => {
                                console.error(">>>>>>>>>>>", res);
                                if (res.code == 200) {
                                    this.userInfo = res.data
                                    console.error(this.userInfo);
                                    this.$message.success("登录成功");
                                    localStorage.setItem("ms_username", this.param.username);
                                    localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
                                    this.$store.state.userInfo = this.userInfo;

                                    this.apiUtils.addToken(this.userInfo);

                                    if ("user" == this.param.account) {
                                        this.$router.push("/devicemanage");
                                    }else {
                                        this.$router.push("/");
                                    }

                                } else {
                                    this.$message.error("登录失败! " + res.errInfo);
                                    console.log(res);
                                }

                            })
                                .catch(error => console.log(error))
                                .finally(() => {
                                });
                        } catch (e) {
                            console.error(">>>>>>>>>>>", e)
                        }


                    } else {
                        this.$message.error("请输入账号和密码");
                        return false;
                    }
                });
            }
        }
    };
</script>

<style scoped>
    >>> .el-tabs__nav-scroll{
        width:50%;
        margin:0 auto
    }

    >>> .el-tabs__item{
        font-size: 18px;
        height: 60px;
        line-height: 60px;
        color: #fff;
    }

    >>> .el-tabs__item.is-active{
        color: #409EFF;
    }

    >>> .el-tabs__nav-scroll{
        width: 70%;
    }

.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/login_bg.jpg);
    background-position: center 0;
    background-size: cover;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    overflow: hidden;
    backdrop-filter: blur(5px)
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>